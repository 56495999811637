/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field, Formik, FieldArray, useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import * as Yup from 'yup';
import WorkplaceService from '../../../__mock__/WorkplaceService';
import {
  useCartDispatch,
  useCartState,
} from '../../context/CartContext/CartContext';
import { useSettingsState } from '../../context/SettingsContext';
import { useUserState, useUserDispatch } from '../../context/UserContext';
import personalCodeValidation from '../../hooks/personalCodeValidation';
import usePhoneValidation from '../../hooks/usePhoneValidator';
import Button from '../../shared/Button';
import Checkbox from '../../shared/Checkbox';
import Container from '../../shared/Container';
import ErrorModal from '../../shared/ErrorModal';
import Form from '../../shared/Form';
import Loader from '../../shared/Loader';
import Modal from '../../shared/Modal';
import NoResults from '../../shared/NoResults';
import Page from '../../shared/Page';
import Tabs from '../../shared/Tabs';
import TextField from '../../shared/TextField';
import Summary from './Summary';
import Styled from './CartPageStyles/CartPage.styles';
import validateEmail from '../../hooks/validateEmail';
import ChildDropdown from '../../shared/ChildDropdown/ChildDropdown';
import { exponeaCapture } from '../../hooks/exponeaCapture';
import uniqid from 'uniqid';
import SelectField from '../../shared/SelectField';
import { insuranceCompanies as insuranceCompaniesConstant } from '../../../constants/InsuranceCompanies';
import Radio from '../../shared/Radio';

const USE_MOCKS = false;

const BasketPage = () => {
  const { t } = useTranslation();
  const { agreements } = useSettingsState();
  const history = useHistory();
  const { step: step, registrationServices: products } = useCartState();
  const dispatch = useCartDispatch();
  const userState = useUserState();
  const [cartItems, setCartItems] = useState([]);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [showPsdfTabName, setShowPsdfTabName] = useState(false);
  const { get, request } = useFetch('/Services/WorkplaceServices');
  const [backPath, setBackPath] = useState();
  const [underAge, setUnderageModalVisibile] = useState(false);
  const [selectedChildEspbiEsi, setSelectedChildEspbiEsi] = useState();
  const validatePhoneNumber = usePhoneValidation();
  const userDispatch = useUserDispatch();
  const [validPhoneNumber, setValidPhoneNumber] = useState();
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState();
  const [showPhoneValidationModal, setShowPhoneValidationModal] =
    useState(false);
  const [showChildAgeRestrictionModal, setShowChildAgeRestrictionModal] =
    useState(false);
  const [showGiftCoupon, setShowGiftCoupon] = useState(false);
  const [showDiscountCoupon, setShowDiscountCoupon] = useState(false);
  const [discountCodeNumber, setDiscountCodeNumber] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalInitialSum, setTotalInitialSum] = useState(0);
  const cartState = useCartState();
  const [initialTotalPrice, setInitialTotalPrice] = useState();
  const [totalCouponsApplied, setTotalCouponsApplied] = useState(0);
  const [changeCount, setChangeCount] = useState(0);
  const [appliedCouponNumbers, setAppliedCouponNumbers] = useState([]);
  const [appliedCoupons, setAppliedCoupons] = useState([]);
  const [errorModalMessage, setErrorModalMessage] = useState();
  const [serviceId, setServiceId] = useState();
  const [kids, setKids] = useState();
  const [childSelected, setChildSelected] = useState(false);
  const [selectedChild, setSelectedChild] = useState('');
  const [priceFromSummary, setPriceFromSummary] = useState(0);
  const [cartStep, setCartStep] = useState(0);

  const [insuranceCompanies, setInsuranceCompanies] = useState(
    insuranceCompaniesConstant,
  );

  const [lastInsurance, setLastInsurance] = useState('');
  const { request: registrationRequest, response: registrationResponse } =
    useFetch('/Registrations');
  const { get: saveValidationLog, response: saveValidationLogResponse } =
    useFetch('/Logs/saveValidationLog');
  const { post: checkCoupon, response: checkCouponResponse } = useFetch(
    '/CouponManagement/CheckCoupon',
  );
  const { post: checkDiscountCode, response: checkDiscountCodeResponse } =
    useFetch('/DiscountManagement/GetDiscount');

  const { get: getServiceTypeId, response: getServiceTypeIdResponse } =
    useFetch('/Services/ServiceTypeId');

  const { request: serviceRequest, response: serviceGetResponse } =
    useFetch('/Services');

  const {
    post: updateLastPersonalInsurance,
    response: updateLastPersonalInsuranceResponse,
    get: getLastPersonalInsurance,
    response: getLastPersonalInsuranceResponse,
  } = useFetch('/UserInsurance/last');

  const [
    validatePersonalCode,
    maxAgeFromPersonalCode,
    minAgeFromPersonalCode,
    birthDateFromPersonalCode,
  ] = personalCodeValidation();

  const { request: timeRequest } = useFetch(`/TimeSlots`);

  const formRef = useRef();
  const formRefCoupons = useRef();
  const formRefDiscount = useRef();
  const checkBoxRef = useRef();
  const requiredMessage = t('validation.requiredField');
  const vgskServiceTypeId = process.env.REACT_APP_VGSK_SERVICE_TYPE_ID;
  const laboratoryServiceTypeExternalId = process.env.REACT_APP_LABEXTERNALID;
  const gastroenterologyServiceTypeExternalId =
    process.env.REACT_APP_GASTROENTEROLOGY_SERVICE_TYPE_EXTERNAL_ID;

  const PaymentMethod = Object.freeze({
    Undefined: 0,
    EBank: 1,
    Insurance: 2,
    Juridical: 3,
    OnSite: 4,
  });

  const TimeSlotPaymentType = Object.freeze({
    Undefined: 0,
    PSDF: 1,
    PAID: 2,
    EXTRAPAID: 3,
  });

  useEffect(() => {
    if (userState?.contacts) {
      const kids = [];
      userState?.contacts.forEach((k) => {
        if (k.personalCode) {
          var testRes = maxAgeFromPersonalCode(k.personalCode, 18);
          if (!testRes) {
            kids.push(k);
          }
        } else {
          kids.push(k);
        }
      });
      setKids(kids);
    }
  }, [userState?.contacts]);

  useEffect(() => {
    setBackPath(history.location.state?.backPath);
    if (USE_MOCKS) {
      setCartItems(
        [WorkplaceService].map((d, i) => {
          d.orderData = products[i];
          return d;
        }),
      );
    } else {
      if (products.length) {
        let multiProducts = addLaboratoryMultipleServicesToProducts(products);
        Promise.all(
          multiProducts.map(({ workplaceId, serviceId }) => {
            setServiceId(serviceId);
            return get(`/${workplaceId}/${serviceId}`);
          }),
        ).then(async (data) => {
          const updatedDataPromises = data.map(async (d, i) => {
            d.orderData = multiProducts[i];
            d.additionalServices = d.additionalServices.concat(
              await setAdditionalServicesByResearchArea(d, multiProducts[i]),
            );
            d.isPaidLaboratoryService =
              multiProducts[i].isPaidLaboratoryService;
            return d;
          });

          const updatedData = await Promise.all(updatedDataPromises);
          setCartItems(updatedData);
        });
      }
    }
  }, [backPath, get, history.location.state?.backPath, products]);

  const setAdditionalServicesByResearchArea = async (
    workplaceService,
    orderData,
  ) => {
    const selectedTimeSlot = orderData?.timeSlot;
    const service = workplaceService;
    if (
      shouldUseAdditionalServicesByResearchArea(workplaceService) &&
      !!selectedTimeSlot &&
      !!selectedTimeSlot?.researchAreaId &&
      selectedTimeSlot.isPaid
    ) {
      const params = new URLSearchParams({
        serviceId: service.serviceId,
        researchAreaId: selectedTimeSlot.researchAreaId,
        timeSlotDateTime: selectedTimeSlot.startDate,
        departmentExternalId: service.departmentExternalId,
      });
      const url = `ResearchAreaDayOfWeekAdditionalServices?${params.toString()}`;
      const response = await serviceRequest.get(url);
      return [...response, ...(service.mandatoryOtherServices ?? [])];
    }

    return service.additionalService ?? [];
  };

  const shouldUseAdditionalServicesByResearchArea = async (
    workplaceService,
  ) => {
    if (!workplaceService || !workplaceService.serviceTypeExternalId) {
      return false;
    }
    return (
      gastroenterologyServiceTypeExternalId ==
      workplaceService.serviceTypeExternalId
    );
  };

  useEffect(() => {
    if (!!discountCodeNumber || !!products[0]?.timeSlot?.isExtra) {
      setInsuranceCompanies(
        insuranceCompanies.filter((company) => company.value !== 'Compensa'),
      );
    } else {
      setInsuranceCompanies(insuranceCompaniesConstant);
    }
  }, [discountCodeNumber, products]);

  useEffect(() => {
    return () => {
      if (history.location.pathname === '/selfservice') {
        dispatch({
          type: 'SET_BACK_URL',
          url: `/checkout`,
        });
      } else if (
        backPath !== undefined &&
        history.location.pathname !== backPath
      ) {
        dispatch({
          type: 'RESET_CART',
        });
      } else if (history.action === 'POP') {
        dispatch({
          type: 'RESET_CART',
        });
      }
    };
  }, [backPath, dispatch, history]);

  useEffect(() => {
    if (showGiftCoupon) {
      handleTotalCoupons(null);
    }
  }, [changeCount, showGiftCoupon]);

  useEffect(() => {
    if (totalPrice > 0) {
      setInitialTotalPrice(totalPrice + totalCouponsApplied);
    }
  }, [totalPrice]);

  useEffect(() => {
    if (products) {
      let singleProducts = [];
      if (products.length) {
        singleProducts = [products[products.length - 1]];
      }
      singleProducts.forEach((prod) => {
        if (!prod.timeSlot.isPaid) {
          setShowPsdfTabName(true);
        }
      });
    }
  }, [products]);

  useEffect(() => {
    if (showGiftCoupon) {
      formRefCoupons.current.values.coupons = [
        { couponNumber: '', couponLabel: false, inputVisible: true },
      ];
    } else {
      setTotalCouponsApplied(0);
    }
  }, [showGiftCoupon]);

  useEffect(() => {
    if (formRefCoupons?.current?.values.coupons.length === 0) {
      setShowGiftCoupon(false);
    }
  }, [formRefCoupons?.current?.values.coupons]);

  function addLaboratoryMultipleServicesToProducts(products) {
    if (
      products[0].laboratoryMultipleServices &&
      products[0].laboratoryMultipleServices.length > 0
    ) {
      const newProducts = products[0].laboratoryMultipleServices.map(
        (service) => {
          const newProduct = {
            ...products[0],
            id: uniqid(),
            serviceId: service.serviceId,
            isPaidLaboratoryService: service.isPaidLaboratoryService,
          };
          delete newProduct.laboratoryMultipleServices;
          return newProduct;
        },
      );
      return [...products, ...newProducts];
    }
    return products;
  }

  const areLaboratoryServices = () => {
    return (
      cartItems[0].serviceTypeExternalId.toString() ===
      laboratoryServiceTypeExternalId.toString()
    );
  };

  const areAnyAdditionalPaidServices = (isPaid) => {
    return cartItems.find(
      (cartItem) =>
        cartItem.automationRuleCodes.includes(isPaid ? 2 : 1) &&
        cartItem.additionalServices?.length > 0 &&
        cartItem.additionalServices?.find((service) => service.price > 0),
    );
  };

  const areNoAdditionalPaidServices = (isPaid) => {
    return cartItems.every(
      (cartItem) =>
        !cartItem.automationRuleCodes.includes(isPaid ? 2 : 1) ||
        cartItem.additionalServices?.length === 0 ||
        cartItem.additionalServices?.every((service) => service.price === 0),
    );
  };

  function handleFormSubmit(formData, { setErrors, setSubmitting }) {
    showPaymentOptions(formData, { setErrors, setSubmitting });
  }

  function showPaymentOptions(formData, { setErrors, setSubmitting }) {
    setSubmitting(true);
    setFormSubmitting(true);
    let paymentType = 'Paysera';
    let singleProducts = [];

    clearPaymentMethodAdditionalValues(formData);

    if (products) {
      singleProducts = cartItems.map((item) => item.orderData);
    }

    singleProducts.forEach((prod, index) => {
      prod.startDate = prod.timeSlot.startDate;
      prod.childName = formData.childName;
      prod.childSurname = formData.childSurname;
      prod.childIdNumber = formData.childIdNumber;

      if (!prod.timeSlot.isPaid && !prod.timeSlot.isExtra) {
        paymentType = 'PSDF';
      }

      if (areLaboratoryServices() && index === 0) {
        prod.isPaidLaboratoryService = prod.timeSlot.isPaid;
      }

      if (prod.childIdNumber) {
        prod.childBirthDate = birthDateFromPersonalCode(formData.childIdNumber);
      }

      if (userState?.contacts) {
        if (prod.childIdNumber) {
          prod.haveKid = true;
          if (
            userState?.contacts.some(
              (x) => x.personalCode === prod.childIdNumber,
            )
          ) {
            prod.correctChild = true;
            prod.espbiEsi = userState?.contacts.filter(
              (x) => x.personalCode === prod.childIdNumber,
            )[0].espbiEsi;
          }
        } else if (childSelected) {
          prod.haveKid = true;
          prod.correctChild = true;
          prod.espbiEsi = selectedChildEspbiEsi;
        }
      }

      prod.timeSlotPaymentType =
        prod.isPaidLaboratoryService === true
          ? TimeSlotPaymentType.PAID
          : prod.isPaidLaboratoryService === false
          ? TimeSlotPaymentType.PSDF
          : prod.timeSlot.isExtra
          ? TimeSlotPaymentType.EXTRAPAID
          : prod.timeSlot.isPaid
          ? TimeSlotPaymentType.PAID
          : TimeSlotPaymentType.PSDF;
    });

    if (cartItems.slice(1).some((item) => item.isPaidLaboratoryService)) {
      paymentType = 'Paysera';
    }

    if (
      paymentType !== 'PSDF' &&
      !!totalCouponsApplied &&
      totalPrice <= 0
    ) {
      paymentType = 'Coupons';
    }

    if (
      paymentType === 'PSDF' &&
      cartItems[0]?.additionalServices?.length !== 0 &&
      !!totalCouponsApplied &&
      totalPrice <= 0
    ) {
      paymentType = 'PSDFWithCoupons';
    }

    if (

      totalPrice > 0 &&
      paymentType !== 'Social' &&
      paymentType !== 'PSDF' &&
      paymentType !== 'PSDFWithCoupons'
    ) {
      paymentType = 'Paysera';
    }

    if (
      formData.paymentMethod === PaymentMethod.Insurance &&
      !!formData.insuranceCompany
    ) {
      paymentType = 'Social';
    }

    if (
      formData.paymentMethod === PaymentMethod.Juridical &&
      !!formData.juridicalPerson
    ) {
      paymentType = 'Juridical';
    }

    if (formData.paymentMethod === 4) {
      paymentType = 'OnSite';
    }

    const form = cloneDeep({
      ...formData,
      couponSum: totalCouponsApplied,
      startDate: cartItems[0]?.orderData?.timeSlot?.startDate,
      registrationServices: singleProducts,
      serviceUrl: cartState.orderUrl,
      paymentType: paymentType,
      expressCareType: cartItems[0]?.orderData?.expressCareType,
      couponNumbers: paymentType !== 'OnSite' ? appliedCouponNumbers : [],
      discountCode: discountCodeNumber,
      TimeSlot: {
        timeSlotId: cartItems[0]?.orderData?.timeSlot?.timeSlotId,
        startDate: cartItems[0]?.orderData?.timeSlot?.startDate,
        endDate: cartItems[0]?.orderData?.timeSlot?.endDate,
        tags: cartItems[0]?.orderData?.timeSlot?.tags,
        researchAreaId: cartItems[0]?.orderData?.timeSlot?.researchAreaId,
      },
      frontUrls: {
        cancelFrontURL:
          (paymentType === 'PSDF' && areNoAdditionalPaidServices(false)) ||
          paymentType === 'Social' ||
          paymentType === 'OnSite' ||
          paymentType === 'Juridical'
            ? 'payment/psdfError'
            : 'payment/error',
        acceptFrontUrl:
          paymentType === 'PSDF' &&
          cartItems[0]?.additionalServices?.length === 0
            ? 'payment/psdfAccept'
            : paymentType === 'Social' ||
              paymentType === 'OnSite' ||
              paymentType === 'Juridical'
            ? 'payment/socialAccept'
            : paymentType === 'PSDF' && areAnyAdditionalPaidServices(false)
            ? 'payment/psdfWithAdditionalPaymentAccept'
            : paymentType === 'PSDFWithCoupons' ||
              paymentType === 'PSDFWithCoupons'
            ? isRemote()
              ? 'payment/remoteCouponAccept'
              : 'payment/couponAccept'
            : isRemote()
            ? 'payment/remoteAccept'
            : 'payment/accept',
        errorFrontUrl:
          (paymentType === 'PSDF' && areNoAdditionalPaidServices(false)) ||
          paymentType === 'Social' ||
          paymentType === 'OnSite' ||
          paymentType === 'Juridical'
            ? 'payment/psdfError'
            : 'payment/error',
      },

      //po-738 tryint to refactor it
      // frontUrls: !areLaboratoryServices()
      //   ? {
      //     cancelFrontURL:
      //       (paymentType === 'PSDF' && cartItems[0]?.additionalServices?.length === 0) ||
      //         paymentType === 'Social' ||  paymentType === 'OnSite'
      //         ? 'payment/psdfError'
      //         : 'payment/error',
      //     acceptFrontUrl:
      //       (paymentType === 'PSDF' && cartItems[0]?.additionalServices?.length === 0) ||
      //         paymentType === 'Social' ||  paymentType === 'OnSite'
      //         ? paymentType === 'Social' ||  paymentType === 'OnSite' ? 'payment/socialAccept' : 'payment/psdfAccept'
      //         : paymentType === 'PSDF' &&
      //           cartItems[0]?.additionalServices?.length > 0
      //           ? 'payment/psdfWithAdditionalPaymentAccept'
      //           : isRemote() ? 'payment/remoteAccept' : 'payment/accept',
      //     errorFrontUrl:
      //       (paymentType === 'PSDF' && cartItems[0]?.additionalServices?.length === 0) ||
      //         paymentType === 'Social' ||  paymentType === 'OnSite'
      //         ? 'payment/psdfError'
      //         : 'payment/error',
      //     test: 1,
      //   }
      //   : {
      //     cancelFrontURL:
      //       (paymentType === 'PSDF' || paymentType === 'Social' ||  paymentType === 'OnSite') &&
      //         areNoAdditionalPaidServices(false)
      //         ? 'payment/psdfError'
      //         : 'payment/error',
      //     acceptFrontUrl:
      //       (paymentType === 'PSDF' || paymentType === 'Social' ||  paymentType === 'OnSite') &&
      //         areNoAdditionalPaidServices(false)
      //         ? paymentType === 'Social' ||  paymentType === 'OnSite' ? 'payment/socialAccept' : 'payment/psdfAccept'
      //         : paymentType === 'PSDF' && areAnyAdditionalPaidServices(false)
      //           ? 'payment/psdfWithAdditionalPaymentAccept'
      //           : 'payment/accept',
      //     errorFrontUrl:
      //       (paymentType === 'PSDF' || paymentType === 'Social' ||  paymentType === 'OnSite') &&
      //         areNoAdditionalPaidServices(false)
      //         ? 'payment/psdfError'
      //         : 'payment/error',
      //     test: 2,
      //   },
    });

    delete form.agreement1;
    delete form.agreement2;
    delete form.agreement3;

    form.registrationServices.forEach((service) => {
      delete service.files;
      delete service.termsAndConditions;
      delete service.timeSlot;
      delete service.id;
    });
    form.patientIdNumber = form.patientIdNumber?.replace(/\s/g, '');

    if (form.patientIdNumber) {
      form.patientBirthDate = birthDateFromPersonalCode(form.patientIdNumber);
    }

    const isService = !cartItems[0].specialistId;
    let baseObj = [
      { name: 'category_1', value: cartItems[0].serviceTypeTitle },
      {
        name: 'category_2',
        value: isService
          ? cartItems[0].serviceTitle
          : cartItems[0].specialistName + ' - ' + cartItems[0].serviceTitle,
      },
      { name: 'price', value: priceFromSummary },
      { name: 'registration type', value: paymentType },
      { name: 'firstName', value: form.patientName },
      { name: 'lastName', value: form.patientSurname },
      { name: 'email', value: form.patientEmail },
      { name: 'product_id', value: cartItems[0].serviceId },
      { name: 'category_id', value: cartItems[0].categoryId },
      { name: 'mobile_phone', value: formData.patientPhoneNumber },
    ];

    if (form.childIdNumber) {
      baseObj.push({ name: 'childName', value: form.childName });
      baseObj.push({ name: 'childSurname', value: form.childSurname });
    }
    exponeaCapture(baseObj, 'booking_item');

    const successFrontUrl = form?.frontUrls?.acceptFrontUrl;

    registrationRequest.post('', form).then(async () => {
      const response = await registrationResponse.json();

      baseObj.push({
        name: 'booking_appointment_id',
        value: cartItems[0]?.orderData?.timeSlot?.timeSlotId,
      });

      baseObj.push({
        name: 'booking_booking_id',
        value: response.orderId,
      });

      baseObj.push({
        name: 'booking_payment_gateway_id',
        value: response.orderId,
      });

      baseObj.push({
        name: 'purchase_total_cost',
        value: priceFromSummary,
      });

      sessionStorage.setItem('exponeaTrack', JSON.stringify(baseObj));

      if (registrationResponse.status >= 500) {
        setSubmitting(false);
        setFormSubmitting(false);
        setErrorModalVisible(true);

        let couponDescriptionString = response.detail.substring(
          31,
          response.detail.length,
        );
        setErrorModalMessage(couponDescriptionString);
      } else if (registrationResponse.status >= 400) {
        const { errors } = response;

        setErrors(errors);
      } else {
        userDispatch({
          type: 'SET_LEFT_CART_PAGE',
          left: true,
        });

        // dispatch({
        //   type: 'RESET_CART',
        // });

        const payseraUrl = response.payseraUrl;

        if (!!payseraUrl) {
          dispatch({
            type: 'SET_REGISTRATION_ID',
            registrationId: response.registrationId,
            redirectedToPaysera: true,
          });

          dispatch({
            type: 'SET_BACK_URL',
            url: `/checkout`,
          });

          dispatch({
            type: 'ADD_TO_CART_PAYMENTURL',
            payseraUrl: payseraUrl,
          });

          userDispatch({
            type: 'SET_LAST_ACTIVE_GROUP',
            lastActiveGroupId: undefined,
          });

          dispatch({
            type: 'REMOVE_TIMER',
          });

          window.location.replace(payseraUrl);
        } else if (!!successFrontUrl && totalPrice !== 0) {
          history.push(`/${successFrontUrl}`);
        } else {
          history.push(`/payment/socialAccept`);
        }

        //po-738 tryint to refactor it
        // if (
        //   response.payseraUrl === '' &&
        //   paymentType !== 'PSDF' &&
        //   paymentType !== 'Social'
        // ) {
        //   dispatch({
        //     type: 'RESET_CART',
        //   });
        //   isRemote() ?
        //     history.push('/payment/remoteCouponAccept') :
        //     history.push('/payment/couponAccept');
        // } else if (
        //   !response.payseraUrl &&
        //   (paymentType === 'PSDF' || paymentType === 'Social') &&
        //   (paymentType !== 'Social' ||
        //     (!areLaboratoryServices() &&
        //       ((paymentType == 'Social' || cartItems[0]?.additionalServices?.length === 0) ||
        //         (await shouldUseAdditionalServicesByResearchArea(
        //           cartItems[0],
        //         )))) ||
        //     paymentType !== 'Social' ||
        //     (areLaboratoryServices() && areNoAdditionalPaidServices(false)))
        // ) {
        //   paymentType === 'Social' ? history.push('/payment/socialAccept') : history.push('/payment/psdfAccept');

        // }

        // if (
        //   (paymentType !== 'PSDF' &&
        //     paymentType !== 'Social' &&
        //     response.payseraUrl !== '') ||
        //   (!areLaboratoryServices() &&
        //     paymentType !== 'Social' &&
        //     cartItems[0]?.additionalServices?.length !== 0 &&
        //     !(await shouldUseAdditionalServicesByResearchArea(cartItems[0]))) ||
        //   (areLaboratoryServices() && areAnyAdditionalPaidServices(false))
        // ) {
        //   dispatch({
        //     type: 'RESET_CART',
        //   });

        //   dispatch({
        //     type: 'SET_REGISTRATION_ID',
        //     registrationId: response.registrationId,
        //     redirectedToPaysera: true,
        //   });

        //   dispatch({
        //     type: 'SET_BACK_URL',
        //     url: `/checkout`,
        //   });

        //   dispatch({
        //     type: 'ADD_TO_CART_PAYMENTURL',
        //     payseraUrl: payseraUrl,
        //   });

        //   userDispatch({
        //     type: 'SET_LAST_ACTIVE_GROUP',
        //     lastActiveGroupId: undefined,
        //   });

        //   window.location.replace(payseraUrl);
        // }
      }
    });
  }

  const handleChildSelect = (child) => {
    const kid = userState?.contacts.find((x) => x.espbiEsi === child.espbiEsi);
    if (kid) {
      setSelectedChild(kid.name);
      setSelectedChildEspbiEsi(kid.espbiEsi);
      formRef.current.values.childName = kid.name;
      formRef.current.values.childSurname = kid.surname;
      formRef.current.values.childIdNumber = kid.personalCode;
      setChildSelected(true);
    } else {
      setChildSelected(false);
      setSelectedChild(child);
      formRef.current.values.childName = child;
    }
  };

  // useEffect(() => {
  //   if (userState?.contacts) {
  //     const tmp = [];
  //     userState?.contacts.forEach((kid) => {
  //       if (kid.espbiEsi) {
  //         const joinedKid = `${kid.name} ${kid.surname} (${kid.personalCode})`;
  //         tmp.push(joinedKid);
  //       }
  //     });
  //     setKids(tmp);
  //   }
  // }, [userState?.contacts]);

  const handleTotalCoupons = (coupons) => {
    let sum = 0;
    formRefCoupons.current.values.coupons.forEach((coupon) => {
      if (coupon.couponBalance) {
        sum += coupon.couponBalance;
      }
    });
    setTotalCouponsApplied(sum);
  };

  const isRemote = () => {
    return !!cartItems.find((item) => item.isServiceRemote);
  };

  const isPaid = () => {
    return cartItems.find((item) => item.orderData?.timeSlot?.isPaid);
  };

  const isPaidCoupons = () => {
    return (
      cartItems.find((item) => item.orderData?.timeSlot?.isPaid) ||
      cartItems[0].additionalServices.length > 0
    );
  };

  const validationSchemaForNotLoggedInUser = () => {
    const schema = {
      patientName: Yup.string()
        .trim()
        .required(t('validation.requiredField'))
        .test(
          'confirmName',
          'Vardo formatas netinkamas',
          function (value, { createError }) {
            const nameRegex = /^\s+|\s+$/g;
            let result = nameRegex.test(value);
            return !result;
          },
        ),
      patientSurname: Yup.string()
        .trim()
        .required(t('validation.requiredField'))
        .test(
          'confirmSurname',
          'Pavardės formatas netinkamas',
          function (value, { createError }) {
            const surnameRegex = /^\s+|\s+$/g;
            let result = surnameRegex.test(value);
            return !result;
          },
        ),
      patientEmail: Yup.string()
        .trim()
        .email(t('validation.invalidEmail'))
        .required(t('validation.requiredField'))
        .test(
          'confirmEmail',
          t('validation.checkEmail'),
          function (value, { createError }) {
            const sideSpaceRegex = /^\s+|\s+$/g;
            let result = sideSpaceRegex.test(value);
            if (!result) {
              return !result;
            }
            let validEmail = validateEmail(value);
            return validEmail;
          },
        )
        .test(
          'latinOnlyEmail',
          t('validation.latinOnlyEmail'),
          function (value) {
            if (!value) return true;
            const latinOnlyRegex = /^[\u0000-\u007F]+$/;
            return latinOnlyRegex.test(value);
          },
        ),
      patientPhoneNumber: Yup.string()
        .trim()
        .required(t('validation.requiredField'))
        .test(
          'phone',
          'Telefono numerio formatas netinkamas',
          (val, { createError }) => {
            if (val === '') {
              return createError({
                message: t('Įveskite telefono numerį'),
                path: 'patientPhoneNumber',
              });
            }
            if (val) {
              let splittedValue = val.split('');
              if (splittedValue.some((v) => v === ' ')) {
                return false;
              }
            }
            var phone = validatePhoneNumber(val);

            if (!phone.valid) {
              return createError({
                message: t('Netinkamas telefono numeris'),
                path: 'patientPhoneNumber',
              });
            } else if (!phone.lt) {
              return true;
            } else if (phone.valid && phone.format && phone.changed) {
              formRef.current.values.patientPhoneNumber = phone.newValue;
              saveValidationLog(
                `?changedFrom=${phone.oldVal}&changedTo=${phone.newValue}`,
              );
              return true;
            } else if (
              phone.valid &&
              phone.format &&
              phone.orgVal &&
              phone.newVal &&
              !phone.showModal
            ) {
              formRef.current.values.patientPhoneNumber = phone.newVal;

              saveValidationLog(
                `?changedFrom=${phone.orgVal}&changedTo=${phone.newVal}`,
              );
              return true;
            } else if (
              phone.valid &&
              phone.format &&
              phone.orgVal &&
              phone.newVal
            ) {
              setValidPhoneNumber(phone.newVal);
              setInvalidPhoneNumber(phone.orgVal);
              setShowPhoneValidationModal(true);
            }
          },
        ),
      patientIdNumber: Yup.string()
        .required(t('validation.requiredField'))
        .trim()
        .test('len', (val, { createError }) => {
          const validatedPersonalCode = validatePersonalCode(
            val?.replace(/\s/g, ''),
          );
          const testResult = maxAgeFromPersonalCode(
            val?.replace(/\s/g, ''),
            18,
          );
          if (!validatedPersonalCode) {
            return createError({
              message: t('validation.personalCode'),
              path: 'patientIdNumber',
            });
          }
          if (!testResult) {
            setUnderageModalVisibile(true);
            return createError({
              message: t('Asmuo nepilnametis'),
              path: 'patientIdNumber',
            });
          }
          return true;
        }),

      otherPerson: Yup.boolean(),
      childName: Yup.string()
        .trim()
        .when('otherPerson', {
          is: true,
          then: Yup.string().required(requiredMessage),
        }),
      childSurname: Yup.string()
        .trim()
        .when('otherPerson', {
          is: true,
          then: Yup.string().required(requiredMessage),
        }),
      childIdNumber: Yup.string()
        .trim()
        .when('otherPerson', {
          is: true,
          then: Yup.string()
            .required(t('validation.requiredField'))
            .trim()
            .test('len', (val, { createError }) => {
              const validatedPersonalCode = validatePersonalCode(val);
              if (!validatedPersonalCode) {
                return createError({
                  message: t('validation.personalCode'),
                  path: 'childIdNumber',
                });
              }
              if (cartItems[0].isChildrenAgeRestrictionApplicable) {
                const testResultMax = maxAgeFromPersonalCode(
                  val,
                  cartItems[0].childAgeTo ?? 18,
                );
                const testResultMin = minAgeFromPersonalCode(
                  val,
                  cartItems[0].childAgeFrom,
                );
                if (testResultMax || testResultMin) {
                  return createError({
                    message: t(
                      `Gydytojas konsultuoja tik vaikus nuo ${cartItems[0].childAgeFrom} metų`,
                    ),
                    path: 'childIdNumber',
                  });
                }
              } else {
                const testResult = maxAgeFromPersonalCode(val, 18);
                if (testResult) {
                  return createError({
                    message: t('Įveskite vaiko iki 18 metų asmens kodą'),
                    path: 'childIdNumber',
                  });
                }
              }
              return true;
            }),
        }),
      termsAndConditions: Yup.bool().when('otherPerson', {
        is: true,
        then: Yup.bool().oneOf([true], requiredMessage),
      }),
    };

    if (!(isPaidCoupons() && totalPrice > 0)) {
      if (isRemote()) {
        schema.agreement1 = Yup.bool().oneOf(
          [true],
          t('validation.requiredField'),
        );
      }

      schema.agreement2 = Yup.bool().oneOf(
        [true],
        t('validation.requiredField'),
      );
      schema.agreement3 = Yup.bool().oneOf(
        [true],
        t('validation.requiredField'),
      );
    }
    return Yup.object().shape(schema);
  };

  useEffect(() => {
    if (cartItems[0]?.isChildrenOnly) {
      formRef.current.setFieldValue('otherPerson', true);
    }
  }, [cartItems]);

  const validationSchemaForLoggedInUser = () => {
    const schema = {
      patientName: Yup.string().required(t('validation.requiredField')).trim(),
      patientSurname: Yup.string()
        .required(t('validation.requiredField'))
        .trim(),
      patientEmail: Yup.string()
        .email(t('validation.invalidEmail'))
        .trim()
        .required(t('validation.requiredField'))
        .test(
          'latinOnlyEmail',
          t('validation.latinOnlyEmail'),
          function (value) {
            if (!value) return true;
            const latinOnlyRegex = /^[\u0000-\u007F]+$/;
            return latinOnlyRegex.test(value);
          },
        ),
      patientIdNumber: Yup.string()
        .trim()
        .test('len', (val, { createError }) => {
          if (userState.containsPersonalCode) {
            return true;
          }
          const validatedPersonalCode = validatePersonalCode(
            val?.replace(/\s/g, ''),
          );
          const testResult = maxAgeFromPersonalCode(
            val?.replace(/\s/g, ''),
            18,
          );
          if (!validatedPersonalCode) {
            return createError({
              message: t('validation.personalCode'),
              path: 'patientIdNumber',
            });
          }
          if (!testResult) {
            setUnderageModalVisibile(true);
            return createError({
              message: t('Asmuo nepilnametis'),
              path: 'patientIdNumber',
            });
          }

          return true;
        }),
      patientPhoneNumber: Yup.string()
        .required(t('validation.requiredField'))
        .trim(),

      otherPerson: Yup.boolean(),
      childName: Yup.string()
        .trim()
        .when('otherPerson', {
          is: true,
          then: Yup.string().required(requiredMessage),
        }),
      childSurname: Yup.string()
        .trim()
        .when('otherPerson', {
          is: true,
          then: Yup.string().required(requiredMessage),
        }),
      childIdNumber: Yup.string()
        .trim()
        .when('otherPerson', {
          is: true,
          then: Yup.string()
            .required(t('validation.requiredField'))
            .trim()
            .test('len', (val, { createError }) => {
              const validatedPersonalCode = validatePersonalCode(val);
              if (!validatedPersonalCode) {
                return createError({
                  message: t('validation.personalCode'),
                  path: 'childIdNumber',
                });
              }
              if (cartItems[0].isChildrenAgeRestrictionApplicable) {
                const testResultMax = maxAgeFromPersonalCode(
                  val,
                  cartItems[0].childAgeTo ?? 18,
                );
                const testResultMin = minAgeFromPersonalCode(
                  val,
                  cartItems[0].childAgeFrom,
                );
                if (testResultMax || testResultMin) {
                  return createError({
                    message: t(
                      `Gydytojas konsultuoja tik vaikus nuo ${cartItems[0].childAgeFrom} metų`,
                    ),
                    path: 'childIdNumber',
                  });
                }
              } else {
                const testResult = maxAgeFromPersonalCode(val, 18);
                if (testResult) {
                  return createError({
                    message: t('Įveskite vaiko iki 18 metų asmens kodą'),
                    path: 'childIdNumber',
                  });
                }
              }
              return true;
            }),
        }),

      termsAndConditions: Yup.bool().when('otherPerson', {
        is: true,
        then: Yup.bool().oneOf([true], requiredMessage),
      }),
    };

    if (!(isPaidCoupons() && totalPrice > 0)) {
      if (isRemote()) {
        schema.agreement1 = Yup.bool().oneOf(
          [true],
          t('validation.requiredField'),
        );
      }

      schema.agreement2 = Yup.bool().oneOf(
        [true],
        t('validation.requiredField'),
      );
      schema.agreement3 = Yup.bool().oneOf(
        [true],
        t('validation.requiredField'),
      );
    }

    return Yup.object().shape(schema);
  };

  const validationSchema = () => {
    return userState.isLoggedIn && userState.isLoggedIn !== null
      ? validationSchemaForLoggedInUser()
      : validationSchemaForNotLoggedInUser();
  };

  if (request.loading) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  }

  const handleAllCartItemsRemove = async () => {
    dispatch({
      type: 'REMOVE_ORDER_URL',
    });
    return await Promise.all(
      cartItems.map(
        async (service, i) =>
          await handleOneCartItemRemove(
            service.orderData.id,
            service.orderData.timeSlotId,
          ),
      ),
    );
  };

  const handleOneCartItemRemove = async (id, timeSlotId) => {
    dispatch({ type: 'REMOVE_FROM_CART', id });
  };

  const handleDataFromChild = (data) => {
    setPriceFromSummary(data);
  };

  const removeDiscountPrices = () => {
    const updatedCartItems = cartItems.map((item) => ({
      ...item,
      priceWithDiscountCode: null,
      discountCode: null,
      additionalServices: item.additionalServices.map((additionalService) => ({
        ...additionalService,
        priceWithDiscountCode: null,
        discountCode: null,
      })),
    }));

    setCartItems(updatedCartItems);
  };

  const clearPaymentMethodAdditionalValues = (values) => {
    if (values.paymentMethod !== PaymentMethod.Juridical) {
      values.juridicalPerson = '';
    }

    if (values.paymentMethod !== PaymentMethod.Insurance) {
      values.insuranceCompany = '';
    }
  };

  const shouldDisplayJuridicalOption = () => {
    return !discountCodeNumber && !appliedCouponNumbers?.length;
  };

  const getTabsConfig = () => [
    {
      name: 'Personal info',
      renderCondition: true,
      schema: () => {
        return validationSchema();
      },
      content: (errors, values, setFieldValue, handleChange) => (
        <>
          <Form.Group>
            {!cartItems[0]?.isAdultsOnly && (
              <>
                <Form.Row>
                  <Checkbox
                    name="otherPerson"
                    checked={values.otherPerson}
                    onChange={() => {
                      if (cartItems[0]?.isChildrenOnly) {
                        setShowChildAgeRestrictionModal(true);
                      } else {
                        if (values.otherPerson) {
                          [
                            'childName',
                            'childSurname',
                            'childBirthDate',
                            'childIdNumber',
                          ].forEach((key) => {
                            setFieldValue(key, '');
                          });
                        }
                        setFieldValue('otherPerson', !values.otherPerson);
                      }
                    }}
                  >
                    {cartItems[0].isChildrenAgeRestrictionApplicable &&
                    cartItems[0].childAgeFrom
                      ? t('registration.form.fieldChildAgeFromToCheckbox', {
                          ageFrom: cartItems[0].childAgeFrom,
                          ageTo: cartItems[0].childAgeTo ?? 18,
                        })
                      : t('registration.form.fieldChildCheckbox')}
                  </Checkbox>
                </Form.Row>
                <Form.Title>{t('cart.patientGroupLabel')}</Form.Title>
                {values.otherPerson && (
                  <Form indent>
                    <Form.Row>
                      {userState?.isLoggedIn && (
                        <Field
                          component={ChildDropdown}
                          onChildSelect={handleChildSelect}
                          name="childName"
                          value={values.childName}
                          availableKids={kids}
                          label={t('registration.form.fieldNameLabel')}
                          onChange={handleChange}
                          error={errors.childName}
                        />
                      )}

                      {!userState?.isLoggedIn && (
                        <Field
                          component={TextField}
                          name="childName"
                          value={values.childName}
                          label={t('registration.form.fieldNameLabel')}
                          onChange={handleChange}
                          error={errors.childName}
                        />
                      )}

                      <Field
                        component={TextField}
                        name="childSurname"
                        value={values.childSurname}
                        label={t('registration.form.fieldSurnameLabel')}
                        onChange={handleChange}
                        error={errors.childSurname}
                      />

                      <Field
                        component={TextField}
                        label={t('cart.fieldCodeLabel')}
                        name="childIdNumber"
                        value={values.childIdNumber?.replace(/\s/g, '')}
                        error={errors.childIdNumber}
                        onChange={handleChange}
                      />
                    </Form.Row>
                  </Form>
                )}
              </>
            )}
            <Form.Group>
              {values.otherPerson && (
                <Form.Title>{t('cart.buyerGroupLabel')}</Form.Title>
              )}
              <Form.Row>
                <Field
                  focus={true}
                  component={TextField}
                  label={t('cart.fieldNameLabel')}
                  name="patientName"
                  error={errors.patientName}
                  value={values.patientName}
                  disabled={userState.isLoggedIn}
                  onChange={handleChange}
                />
                <Field
                  component={TextField}
                  label={t('cart.fieldSurnameLabel')}
                  name="patientSurname"
                  error={errors.patientSurname}
                  value={values.patientSurname}
                  disabled={userState.isLoggedIn}
                  onChange={handleChange}
                />
              </Form.Row>
            </Form.Group>
            <Form.Row>
              <Field
                component={TextField}
                label={t('cart.fieldCodeLabel')}
                name="patientIdNumber"
                error={errors.patientIdNumber}
                value={values.patientIdNumber?.replace(/\s/g, '')}
                disabled={
                  userState.isLoggedIn && userState.containsPersonalCode
                    ? true
                    : false
                }
                onChange={handleChange}
              />
              <div />
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Title>{t('cart.contactGroupLabel')}</Form.Title>
            <Form.Row>
              <Form.Item>
                <Field
                  component={TextField}
                  label={t('cart.fieldPhoneLabel')}
                  name="patientPhoneNumber"
                  error={errors.patientPhoneNumber}
                  value={values.patientPhoneNumber?.replace(/\s/g, '')}
                  disabled={userState.isLoggedIn}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item>
                <Field
                  component={TextField}
                  label={t('cart.fielEmailLabel')}
                  name="patientEmail"
                  error={errors.patientEmail}
                  value={values.patientEmail?.replace(/\s/g, '')}
                  disabled={userState.isLoggedIn}
                  onChange={handleChange}
                />
              </Form.Item>
            </Form.Row>
          </Form.Group>
          {!(isPaidCoupons() && totalPrice > 0) && (
            <Form.Group>
              <Form.Title>{t('cart.agreementGroulLabel')}</Form.Title>
              {isRemote() && (
                <Form.Row>
                  <Checkbox
                    name="agreement1"
                    value="true"
                    onChange={handleChange}
                    error={errors.agreement1}
                  >
                    <Trans
                      i18nKey="cart.fieldAgreement1Label"
                      components={{
                        a: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            href={agreements.rules.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                      }}
                    />
                  </Checkbox>
                </Form.Row>
              )}
              <Form.Row>
                <Checkbox
                  name="agreement2"
                  value="true"
                  onChange={handleChange}
                  error={errors.agreement2}
                >
                  <Trans
                    i18nKey="cart.fieldAgreement2Label"
                    components={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href={agreements.personalData.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                </Checkbox>
              </Form.Row>
              <Form.Row>
                <Checkbox
                  name="agreement3"
                  value="true"
                  onChange={handleChange}
                  error={errors.agreement3}
                >
                  <Trans
                    i18nKey="cart.fieldAgreement3Label"
                    components={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href={agreements.termsOfUse.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                </Checkbox>
              </Form.Row>
              <Form.Row>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '13px',
                    margin: '0px',
                    marginTop: '-15px',
                    padding: '4px 0 4px 40px',
                    color: '#9395a9',
                  }}
                >
                  {t('cart.fieldAgreement4Label')}
                </p>
              </Form.Row>
            </Form.Group>
          )}
        </>
      ),
    },
    {
      name: 'Payment types',
      renderCondition: isPaidCoupons() && totalPrice > 0,
      schema: () => {
        const validationSchema = {
          paymentMethod: Yup.number()
            .required('Pasirinkimas privalomas')
            .min(1, 'Pasirinkimas privalomas'),
          agreement2: Yup.bool().oneOf([true], t('validation.requiredField')),
          agreement3: Yup.bool().oneOf([true], t('validation.requiredField')),
        };

        if (isRemote()) {
          validationSchema.agreement1 = Yup.bool().oneOf(
            [true],
            t('validation.requiredField'),
          );
        }

        validationSchema.juridicalPerson = Yup.string()
          .trim()
          .required(t('validation.requiredField'));

        validationSchema.juridicalPerson = Yup.string()
          .trim()
          .when('paymentMethod', {
            is: PaymentMethod.Juridical,
            then: (schema) => schema.required(t('validation.requiredField')),
            otherwise: (schema) => schema.notRequired(),
          });

        validationSchema.insuranceCompany = Yup.string()
          .trim()
          .when('paymentMethod', {
            is: PaymentMethod.Insurance,
            then: (schema) => schema.required(t('validation.requiredField')),
            otherwise: (schema) => schema.notRequired(),
          });

        return Yup.object().shape(validationSchema);
      },
      onBack: (values) => {
        values.paymentMethod = PaymentMethod.Undefined;
        clearPaymentMethodAdditionalValues(values);
      },
      content: (errors, values, setFieldValue, handleChange) => (
        <>
          <Form.Group title={'Mokėjimo būdai'} error={errors.paymentMethod}>
            <Form.Row key={'paymentMethod.eBank'}>
              <Radio
                name="paymentMethod"
                value={PaymentMethod.EBank}
                error={errors.paymentMethod}
                onChange={(value) => {
                  setFieldValue('paymentMethod', value);
                }}
                checked={values.paymentMethod === PaymentMethod.EBank}
              >
                <Styled.PaymentTypeTitle>
                  Elektroninė bankininkystė
                </Styled.PaymentTypeTitle>
              </Radio>
            </Form.Row>
            {values.paymentMethod === PaymentMethod.EBank && (
              <Form.Row>
                Pasirinkus šį apmokėjimo būdą, būsite automatiškai nukreipti į
                Paysera sistemą, kur galėsite apmokėti per pasirinkto banko
                internetinę bankininkystę.
              </Form.Row>
            )}
            <Form.Row key={'paymentMethod.insurance'}>
              <Radio
                name="paymentMethod"
                value={PaymentMethod.Insurance}
                error={errors.paymentMethod}
                onChange={(value) => {
                  setFieldValue('paymentMethod', value);
                }}
                checked={values.paymentMethod === PaymentMethod.Insurance}
              >
                <Styled.PaymentTypeTitle>
                  Apmokėti privačiu draudimu
                </Styled.PaymentTypeTitle>
              </Radio>
            </Form.Row>
            {values.paymentMethod === PaymentMethod.Insurance && (
              <>
                <Form.Row>
                  Pasirinkus šį apmokėjimo būdą patvirtinate ir garantuojate,
                  kad turite ir asmens sveikatos priežiūros paslaugų teikimo
                  metu turėsite galiojantį savanoriškąjį (papildomą) sveikatos
                  draudimą su pakankamu draudimo sumos likučiu. Jei draudimo
                  bendrovė neapmokės už suteiktas paslaugas arba sumokėtas sumas
                  pareikalaus grąžinti, už suteiktas paslaugas prašysime
                  apmokėti per 5 (penkias) kalendorines dienas nuo mokėjimo
                  pranešimo išsiuntimo dienos.
                </Form.Row>
                <Form.Row>
                  <SelectField
                    error={errors.insuranceCompany}
                    defaultValue={
                      insuranceCompanies.find(
                        (company) => company.value === values.insuranceCompany,
                      ) || {
                        value: values.insuranceCompany,
                        label: values.insuranceCompany,
                      }
                    }
                    name="insuranceCompany"
                    label="Nurodykite draudimo įmonę"
                    options={insuranceCompanies}
                    onChange={(value) => {
                      setFieldValue('insuranceCompany', value);
                    }}
                  />
                </Form.Row>
              </>
            )}
            {shouldDisplayJuridicalOption() && (
              <>
                <Form.Row key={'paymentMethod.juridical'}>
                  <Radio
                    name="paymentMethod"
                    value={PaymentMethod.Juridical}
                    error={errors.paymentMethod}
                    onChange={(value) => {
                      setFieldValue('paymentMethod', value);
                    }}
                    checked={values.paymentMethod === PaymentMethod.Juridical}
                  >
                    <Styled.PaymentTypeTitle>
                      Mokės juridinis mokėtojas
                    </Styled.PaymentTypeTitle>
                  </Radio>
                </Form.Row>
                {values.paymentMethod === PaymentMethod.Juridical && (
                  <>
                    <Form.Row>
                      Pasirinkus šį apmokėjimo būdą patvirtinate ir
                      garantuojate, kad nurodytas juridinis mokėtojas apmokės už
                      pasirinktą paslaugą. Jei juridinis mokėtojas neapmokės už
                      suteiktas paslaugas arba sumokėtas sumas pareikalaus
                      grąžinti, už suteiktas paslaugas prašysime apmokėti per 5
                      (penkias) kalendorines dienas nuo mokėjimo pranešimo
                      išsiuntimo dienos.
                    </Form.Row>
                    <Form.Row>
                      <Field
                        component={TextField}
                        name="juridicalPerson"
                        value={values.juridicalPerson}
                        label="Nurodykite juridinio asmens pavadinimą"
                        onChange={handleChange}
                        error={errors.juridicalPerson}
                      />
                    </Form.Row>
                  </>
                )}
              </>
            )}

            {!isRemote() && (
              <>
                <Form.Row key={'paymentMethod.onSite'}>
                  <Radio
                    name="paymentMethod"
                    value={PaymentMethod.OnSite}
                    error={errors.paymentMethod}
                    onChange={(value) => {
                      setFieldValue('paymentMethod', value);
                    }}
                    checked={values.paymentMethod === PaymentMethod.OnSite}
                  >
                    <Styled.PaymentTypeTitle>
                      Mokėti atvykus padalinyje
                    </Styled.PaymentTypeTitle>
                  </Radio>
                </Form.Row>
                {values.paymentMethod === PaymentMethod.OnSite && (
                  <Form.Row>
                    Pasirinkus šį mokėjimo būdą patvirtinate, kad už paslaugas
                    atsiskaitysite Affidea padalinyje.
                  </Form.Row>
                )}
              </>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Title>{t('cart.agreementGroulLabel')}</Form.Title>
            {isRemote() && (
              <Form.Row>
                <Checkbox
                  name="agreement1"
                  value="true"
                  onChange={handleChange}
                  error={errors.agreement1}
                >
                  <Trans
                    i18nKey="cart.fieldAgreement1Label"
                    components={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href={agreements.rules.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                </Checkbox>
              </Form.Row>
            )}
            <Form.Row>
              <Checkbox
                name="agreement2"
                value="true"
                onChange={handleChange}
                error={errors.agreement2}
              >
                <Trans
                  i18nKey="cart.fieldAgreement2Label"
                  components={{
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={agreements.personalData.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              </Checkbox>
            </Form.Row>
            <Form.Row>
              <Checkbox
                name="agreement3"
                value="true"
                onChange={handleChange}
                error={errors.agreement3}
              >
                <Trans
                  i18nKey="cart.fieldAgreement3Label"
                  components={{
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={agreements.termsOfUse.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              </Checkbox>
            </Form.Row>
            <Form.Row>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '13px',
                  margin: '0px',
                  marginTop: '-15px',
                  padding: '4px 0 4px 40px',
                  color: '#9395a9',
                }}
              >
                {t('cart.fieldAgreement4Label')}
              </p>
            </Form.Row>
          </Form.Group>
        </>
      ),
    },
  ];

  const getRenderableTabsConfig = () => {
    return getTabsConfig().filter((x) => x.renderCondition);
  };

  return (
    <Page>
      <Container>
        <Page.Title
          backUrl={history.location.state.backPath}
          backData={{
            lastStep: step,
            registrationServices: products,
            resetStorage: false,
          }}
        >
          {t('cart.title')}
        </Page.Title>
        {!!cartItems.length ? (
          <>
            <Summary
              items={cartItems}
              couponSum={totalCouponsApplied}
              onRemove={async (id, timeSlotId) => {
                await handleOneCartItemRemove(id, timeSlotId);
                history.push('/');
              }}
              totalPrice={(s) => {
                setTotalPrice(s);
              }}
              totalInitialPrice={(s) => {
                setTotalInitialSum(s);
              }}
              isMarginBottom={true}
              onDataFromChild={handleDataFromChild}
            />

            {isPaidCoupons() && cartStep === 0 && (
              <Styled.CouponFieldsParent>
                <Styled.CouponFields active={true}>
                  <Formik
                    innerRef={formRefDiscount}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                      discount: {
                        discountCode: discountCodeNumber,
                        couponSecondaryLabel: false,
                        couponLabel: !!discountCodeNumber,
                        inputVisible: !discountCodeNumber,
                      },
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      setFieldError,
                      setFieldValue,
                    }) => (
                      <>
                        <Form>
                          <Form.Group>
                            {isPaidCoupons() && (
                              <Styled.CouponCheckbox>
                                <Checkbox
                                  showBorder={true}
                                  value={showDiscountCoupon}
                                  checked={showDiscountCoupon}
                                  onChange={(e) => {
                                    setShowDiscountCoupon(e.target.checked);
                                    if (!e.target.checked) {
                                      setFieldError(
                                        `discount.discountCode`,
                                        '',
                                      );
                                      removeDiscountPrices();
                                      values.discount.discountCode = '';
                                      values.discount.couponSecondaryLabel =
                                        false;
                                      values.discount.couponLabel = false;
                                      values.discount.inputVisible = true;
                                      setDiscountCodeNumber('');
                                    }
                                  }}
                                  innerRef={checkBoxRef}
                                >
                                  <Styled.Trans>
                                    <Trans i18nKey="cart.discountCodeCheckbox" />
                                  </Styled.Trans>
                                </Checkbox>
                              </Styled.CouponCheckbox>
                            )}
                            <div>
                              {showDiscountCoupon && (
                                <Styled.MainContainerDiv message={false}>
                                  {values.discount.inputVisible && (
                                    <Form.Row>
                                      <Styled.CouponFieldsContainer>
                                        <Field
                                          focus={true}
                                          type={'text'}
                                          component={TextField}
                                          label={t(
                                            'cart.fieldDiscountCodeLabel',
                                          )}
                                          name={`discount.discountCode`}
                                          value={
                                            values.discount.discountCode || ''
                                          }
                                          onChange={handleChange}
                                        />
                                        <Styled.CouponButtonContainer>
                                          <Button
                                            size="large"
                                            onClick={() => {
                                              let dc =
                                                values.discount.discountCode.toString();

                                              if (dc === '') {
                                                setFieldError(
                                                  `discount.discountCode`,
                                                  t('coupons.couponEmpty'),
                                                );
                                                return;
                                              }

                                              Promise.all(
                                                cartItems.flatMap((item) => {
                                                  const primaryPromise =
                                                    checkDiscountCode({
                                                      discountCode:
                                                        dc.toString(),
                                                      serviceId: item.serviceId,
                                                      departmentId:
                                                        item.departmentId,
                                                      visitType: item?.orderData
                                                        ?.timeSlot?.isExtra
                                                        ? 'Extra'
                                                        : !item?.orderData
                                                            ?.primaryVisit
                                                        ? 'Secondary'
                                                        : 'Main',
                                                    });

                                                  const additionalPromises =
                                                    item.additionalServices?.map(
                                                      (addServ) => {
                                                        return checkDiscountCode(
                                                          {
                                                            discountCode:
                                                              dc.toString(),
                                                            serviceId:
                                                              addServ.serviceId,
                                                            departmentId:
                                                              item.departmentId,
                                                            visitType: 'Main',
                                                          },
                                                        );
                                                      },
                                                    ) || [];

                                                  return [
                                                    primaryPromise,
                                                    ...additionalPromises,
                                                  ];
                                                }),
                                              )
                                                .then((data) => {
                                                  if (
                                                    !data ||
                                                    data.some(
                                                      (result) =>
                                                        result.status ===
                                                        'rejected',
                                                    )
                                                  ) {
                                                    setFieldError(
                                                      `discount.discountCode`,
                                                      'Įvyko klaida bandant pritaikytį nuolaidos kodą',
                                                    );
                                                    return;
                                                  }
                                                  const discounts = data;
                                                  if (
                                                    discounts.every(
                                                      (item) =>
                                                        !item.isDiscountFound,
                                                    )
                                                  ) {
                                                    setFieldError(
                                                      `discount.discountCode`,
                                                      t(
                                                        'coupons.discountCodeNotFound',
                                                      ),
                                                    );
                                                    return;
                                                  }

                                                  if (
                                                    discounts.every(
                                                      (item) =>
                                                        !item.isApplicableByDate,
                                                    )
                                                  ) {
                                                    setFieldError(
                                                      `discount.discountCode`,
                                                      'Nuolaidos kodas negalioja',
                                                    );
                                                    return;
                                                  }

                                                  if (
                                                    discounts.length === 1 &&
                                                    discounts[0].price >=
                                                      totalInitialSum
                                                  ) {
                                                    if (
                                                      cartItems[0].discountType
                                                    ) {
                                                      setFieldError(
                                                        `discount.discountCode`,
                                                        `Jums pritaikyta "${cartItems[0].discountType}" nuolaida. Nuolaidos nesumuojamos.`,
                                                      );
                                                    } else {
                                                      setFieldError(
                                                        `discount.discountCode`,
                                                        `Jums jau pritaikyta didesnė nuolaida. Nuolaidos nesumuojamos.`,
                                                      );
                                                    }

                                                    return;
                                                  }

                                                  setCartItems((currentItems) =>
                                                    currentItems.map((item) => {
                                                      // Update the main item with its discount
                                                      const itemDiscount =
                                                        discounts.find(
                                                          (discount) =>
                                                            discount.serviceId ===
                                                              item.serviceId &&
                                                            discount.isDiscountFound,
                                                        );
                                                      const updatedItem =
                                                        itemDiscount
                                                          ? {
                                                              ...item,
                                                              isDiscountFound:
                                                                itemDiscount.isDiscountFound,
                                                              priceWithDiscountCode:
                                                                itemDiscount.price,
                                                              discountCode:
                                                                itemDiscount.discountCode,
                                                            }
                                                          : item;

                                                      // Update additional services with their discounts
                                                      if (
                                                        updatedItem.additionalServices
                                                      ) {
                                                        updatedItem.additionalServices =
                                                          updatedItem.additionalServices.map(
                                                            (service) => {
                                                              const serviceDiscount =
                                                                discounts.find(
                                                                  (discount) =>
                                                                    discount.serviceId ===
                                                                      service.serviceId &&
                                                                    discount.isDiscountFound,
                                                                );
                                                              return serviceDiscount
                                                                ? {
                                                                    ...service,
                                                                    isDiscountFound:
                                                                      serviceDiscount.isDiscountFound,
                                                                    priceWithDiscountCode:
                                                                      serviceDiscount.price,
                                                                    discountCode:
                                                                      serviceDiscount.discountCode,
                                                                  }
                                                                : service;
                                                            },
                                                          );
                                                      }

                                                      return updatedItem;
                                                    }),
                                                  );

                                                  setFieldError(
                                                    `discount.discountCode`,
                                                    '',
                                                  );
                                                  setFieldValue(
                                                    `discount.couponLabel`,
                                                    true,
                                                  );
                                                  setFieldValue(
                                                    `discount.inputVisible`,
                                                    false,
                                                  );
                                                  setDiscountCodeNumber(dc);
                                                })
                                                .catch((error) => {
                                                  setFieldError(
                                                    `discount.discountCode`,
                                                    `Įvyko klaida bandant pritaikytį nuolaidos kodą.`,
                                                  );
                                                  return;
                                                });
                                            }}
                                          >
                                            Pritaikyti
                                          </Button>
                                        </Styled.CouponButtonContainer>
                                      </Styled.CouponFieldsContainer>
                                    </Form.Row>
                                  )}
                                  {values.discount.couponLabel && (
                                    <Form.Row>
                                      <Styled.CouponFieldsContainer>
                                        <Styled.CouponParagraphContainer>
                                          <p>{`Nuolaidos kodas "${discountCodeNumber}" priimtas`}</p>
                                        </Styled.CouponParagraphContainer>
                                      </Styled.CouponFieldsContainer>
                                    </Form.Row>
                                  )}
                                </Styled.MainContainerDiv>
                              )}
                            </div>
                          </Form.Group>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Styled.CouponFields>
              </Styled.CouponFieldsParent>
            )}

            {isPaidCoupons() && cartStep === 0 && (
              <Styled.CouponFieldsParent>
                <Styled.CouponFields active={true}>
                  <Formik
                    innerRef={formRefCoupons}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                      coupons:
                        appliedCoupons.length > 0
                          ? appliedCoupons
                          : [
                              {
                                couponNumber: '',
                                couponSecondaryLabel: false,
                                couponBalance: 0,
                                couponLabel: false,
                                couponButton: false,
                                inputVisible: true,
                              },
                            ],
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      setFieldError,
                      setFieldValue,
                    }) => (
                      <>
                        <Form>
                          <Form.Group>
                            {isPaidCoupons() && (
                              <Styled.CouponCheckbox>
                                <Checkbox
                                  showBorder={true}
                                  value={showGiftCoupon}
                                  checked={showGiftCoupon}
                                  onChange={(e) => {
                                    setShowGiftCoupon(e.target.checked);
                                    if (!e.target.checked) {
                                      setAppliedCouponNumbers((prev) =>
                                        prev.filter((cn) => false),
                                      );
                                      setAppliedCoupons([]);
                                    }
                                  }}
                                  innerRef={checkBoxRef}
                                >
                                  <Styled.Trans>
                                    <Trans i18nKey="cart.couponCheckbox" />
                                  </Styled.Trans>
                                </Checkbox>
                              </Styled.CouponCheckbox>
                            )}
                            {showGiftCoupon && (
                              <FieldArray
                                name="coupons"
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.coupons.map((coupon, index) => {
                                      return (
                                        <Styled.MainContainerDiv
                                          message={false}
                                        >
                                          {values.coupons[index]
                                            .inputVisible && (
                                            <Form.Row>
                                              <Styled.CouponFieldsContainer>
                                                <Field
                                                  focus={true}
                                                  type={'text'}
                                                  component={TextField}
                                                  label={t(
                                                    'cart.fieldCouponLabel',
                                                  )}
                                                  name={`coupons.${index}.couponNumber`}
                                                  value={
                                                    values.coupons[index]
                                                      .couponNumber || ''
                                                  }
                                                  onChange={handleChange}
                                                />
                                                <Styled.CouponButtonContainer>
                                                  <Button
                                                    size="large"
                                                    onClick={async (value) => {
                                                      let cp =
                                                        values.coupons[
                                                          index
                                                        ].couponNumber.toString();

                                                      setFieldValue(
                                                        `coupons[${index}].couponSecondaryLabel`,
                                                        false,
                                                      );

                                                      if (
                                                        appliedCouponNumbers.includes(
                                                          cp,
                                                        )
                                                      ) {
                                                        setFieldError(
                                                          `coupons[${index}].couponNumber`,
                                                          t(
                                                            'coupons.couponUsed',
                                                          ),
                                                        );
                                                        return;
                                                      }

                                                      if (totalPrice <= 0) {
                                                        setFieldError(
                                                          `coupons[${index}].couponNumber`,
                                                          t(
                                                            'coupons.overPrice',
                                                          ),
                                                        );
                                                        return;
                                                      }

                                                      const response =
                                                        await checkCoupon(' ', {
                                                          couponNumber:
                                                            cp.toString(),
                                                          serviceId: serviceId,
                                                          departmentId:
                                                            cartItems[0]
                                                              .departmentExternalId,
                                                        });

                                                      if (
                                                        checkCouponResponse.status ===
                                                        400
                                                      ) {
                                                        setFieldError(
                                                          `coupons[${index}].couponNumber`,
                                                          t(
                                                            'coupons.couponNotFound',
                                                          ),
                                                        );
                                                        return;
                                                      }

                                                      if (
                                                        checkCouponResponse.status >
                                                        199
                                                      ) {
                                                        if (
                                                          response.rejectReason ==
                                                          'Expired'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponExpired',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.rejectReason ==
                                                          'Empty'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponBalanceEmpty',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.rejectReason ==
                                                          'Restricted'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponOtherService',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.rejectReason ==
                                                          'CompanyMismatch'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponCompany',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.statusId ===
                                                          'Rezervuotas'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponReserved',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.statusId ===
                                                          'Darbinis'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponOther',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.statusId ===
                                                          'Nebegalioja'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponExpired',
                                                            ),
                                                          );
                                                          return;
                                                        }
                                                        if (
                                                          response.statusId ===
                                                          'Panaudotas'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponSingleUsed',
                                                            ),
                                                          );
                                                          return;
                                                        }
                                                        if (
                                                          response.statusId ===
                                                          'Anuliuotas'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponCancelled',
                                                            ),
                                                          );
                                                          return;
                                                        }
                                                        if (
                                                          response.statusId !==
                                                          'Galioja'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponNonApplicable',
                                                            ),
                                                          );
                                                          return;
                                                        }
                                                      }

                                                      if (cp === '') {
                                                        setFieldError(
                                                          `coupons[${index}].couponNumber`,
                                                          t(
                                                            'coupons.couponEmpty',
                                                          ),
                                                        );
                                                        return;
                                                      }

                                                      setAppliedCouponNumbers(
                                                        (prev) => [...prev, cp],
                                                      );

                                                      setAppliedCoupons(
                                                        (prev) => {
                                                          const updatedCoupons =
                                                            [...prev];
                                                          updatedCoupons[
                                                            index
                                                          ] = {
                                                            couponNumber: cp,
                                                            couponSecondaryLabel:
                                                              false,
                                                            couponBalance:
                                                              response.balance,
                                                            couponLabel: true,
                                                            couponButton: true,
                                                            inputVisible: false,
                                                          };

                                                          return updatedCoupons;
                                                        },
                                                      );

                                                      setFieldError(
                                                        `coupons[${index}].couponNumber`,
                                                        '',
                                                      );
                                                      setFieldValue(
                                                        `coupons[${index}].couponLabel`,
                                                        true,
                                                      );
                                                      setFieldValue(
                                                        `coupons[${index}].couponBalance`,
                                                        response.balance,
                                                      );
                                                      setFieldValue(
                                                        `coupons[${index}].couponButton`,
                                                        true,
                                                      );
                                                      setFieldValue(
                                                        `coupons[${index}].inputVisible`,
                                                        false,
                                                      );
                                                      setChangeCount(
                                                        (prev) => prev + 1,
                                                      );
                                                    }}
                                                  >
                                                    Pritaikyti
                                                  </Button>
                                                </Styled.CouponButtonContainer>
                                              </Styled.CouponFieldsContainer>
                                            </Form.Row>
                                          )}
                                          {values.coupons[index]
                                            .couponLabel && (
                                            <Form.Row>
                                              <Styled.CouponFieldsContainer>
                                                <Styled.CouponParagraphContainer>
                                                  <p>
                                                    {' '}
                                                    {`Dovanų kuponas Nr.${values.coupons[index].couponNumber}, vertė
                                                  ${values.coupons[index].couponBalance}€`}
                                                  </p>
                                                </Styled.CouponParagraphContainer>
                                                <Styled.CouponButtonContainer>
                                                  <Button
                                                    size="large"
                                                    type="secondary"
                                                    style={{
                                                      backgroundColor: '#fff',
                                                      color: '#000',
                                                    }}
                                                    onClick={() => {
                                                      arrayHelpers.remove(
                                                        index,
                                                      );
                                                      setChangeCount(
                                                        (prev) => prev + 1,
                                                      );

                                                      setAppliedCouponNumbers(
                                                        (prev) =>
                                                          prev.filter(
                                                            (cp) =>
                                                              cp !==
                                                              values.coupons[
                                                                index
                                                              ].couponNumber,
                                                          ),
                                                      );

                                                      setAppliedCoupons(
                                                        (prev) => {
                                                          return prev.filter(
                                                            (_, inerIndex) =>
                                                              inerIndex !==
                                                              index,
                                                          );
                                                        },
                                                      );
                                                    }}
                                                  >
                                                    Pašalinti
                                                  </Button>
                                                </Styled.CouponButtonContainer>
                                              </Styled.CouponFieldsContainer>
                                            </Form.Row>
                                          )}
                                        </Styled.MainContainerDiv>
                                        //end of main div
                                      );
                                    })}
                                    {values.coupons &&
                                      values.coupons[values.coupons.length - 1]
                                        ?.couponButton && (
                                        <Form.Row>
                                          <Button
                                            size="large"
                                            type="secondary"
                                            onClick={() => {
                                              arrayHelpers.insert(
                                                values.coupons.length + 1,
                                                {
                                                  couponNumber: '',
                                                  couponLabel: false,
                                                  inputVisible: true,
                                                },
                                              );
                                              setAppliedCoupons((prev) => [
                                                ...prev,
                                                {
                                                  couponNumber: '',
                                                  couponSecondaryLabel: false,
                                                  couponBalance: 0,
                                                  couponLabel: false,
                                                  couponButton: false,
                                                  inputVisible: true,
                                                },
                                              ]);
                                            }}
                                          >
                                            Pridėti dar vieną dovanų kuponą
                                          </Button>
                                        </Form.Row>
                                      )}
                                  </div>
                                )}
                              />
                            )}
                          </Form.Group>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Styled.CouponFields>
              </Styled.CouponFieldsParent>
            )}

            <Tabs active={products[0]?.activeTabs?.length} marginTop={true}>
              <Tabs.List>
                {products[0]?.activeTabs?.map((tab) => (
                  <Tabs.Tab key={tab}>{tab}</Tabs.Tab>
                ))}
                <Tabs.Tab key={t('cart.tab1Name')}>
                  {t('cart.tab1Name')}
                </Tabs.Tab>
                <Tabs.Tab
                  key={
                    showPsdfTabName ? t('cart.tab2Psdf') : t('cart.tab2Name')
                  }
                >
                  {showPsdfTabName ? t('cart.tab2Psdf') : t('cart.tab2Name')}
                </Tabs.Tab>
              </Tabs.List>

              {products[0]?.activeTabs?.map((tab) => (
                <Tabs.Content key={tab}></Tabs.Content>
              ))}
              <Tabs.Content key={t('cart.tab1Name')}>
                <Formik
                  innerRef={formRef}
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    patientName: userState.firstName || '',
                    patientSurname: userState.lastName || '',
                    patientEmail: userState.email || '',
                    patientPhoneNumber: userState.phoneNumber || '',
                    patientIdNumber: userState.containsPersonalCode
                      ? userState.personalCode
                      : '',
                    agreement1: false,
                    agreement2: false,
                    agreement3: false,
                    otherPerson: false,
                    childName: userState.childName || '',
                    childSurname: userState.childSurname || '',
                    childIdNumber: userState.childIdNumber || '',
                    insuranceNumber: '',
                    insuranceCompany: '',
                    juridicalPerson: '',
                    paymentMethod: PaymentMethod.Undefined,
                  }}
                  validationSchema={getRenderableTabsConfig()[
                    cartStep
                  ].schema()}
                  onSubmit={handleFormSubmit}
                >
                  {({
                    values,
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    errors,
                    setFieldValue,
                  }) => (
                    <>
                      <Form>
                        {getRenderableTabsConfig()[cartStep].content(
                          errors,
                          values,
                          setFieldValue,
                          handleChange,
                        )}
                      </Form>
                      <Form.Actions>
                        <Styled.FormButtons>
                          <Button
                            style={{
                              marginRight: '10px',
                              marginTop: '10px',
                              padding: '10px',
                            }}
                            size="large"
                            variant="secondary"
                            onClick={() => {
                              if (cartStep > 0) {
                                getRenderableTabsConfig()[cartStep].onBack(
                                  values,
                                );
                                setCartStep(cartStep - 1);
                              } else {
                                handleAllCartItemsRemove();
                                history.push(history.location.state.backPath, {
                                  lastStep: step,
                                  registrationServices: products,
                                  resetStorage: false,
                                });
                              }
                            }}
                          >
                            Atgal
                          </Button>
                          <Button
                            style={{
                              marginRight: '10px',
                              marginTop: '10px',
                              padding: '10px',
                            }}
                            size="large"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            onClick={() => {
                              getRenderableTabsConfig().length - 1 ===
                                cartStep || !(isPaidCoupons() && totalPrice > 0)
                                ? handleSubmit()
                                : formRef.current
                                    .validateForm()
                                    .then((errors) => {
                                      if (!Object.keys(errors).length) {
                                        setCartStep(cartStep + 1);
                                      }
                                    });
                            }}
                          >
                            {getRenderableTabsConfig().length - 1 ===
                              cartStep || !(isPaidCoupons() && totalPrice > 0)
                              ? 'Patvirtinti'
                              : 'Tęsti'}
                          </Button>
                        </Styled.FormButtons>
                      </Form.Actions>
                    </>
                  )}
                </Formik>
              </Tabs.Content>
              <Tabs.Content
                key={showPsdfTabName ? t('cart.tab2Psdf') : t('cart.tab2Name')}
              ></Tabs.Content>
            </Tabs>
          </>
        ) : (
          <NoResults>Prekių krepšelis tuščias</NoResults>
        )}
      </Container>
      <ErrorModal
        onClose={() => {
          setErrorModalVisible(false);
          dispatch({
            type: 'RESET_CART',
          });
          history.push(backPath);
        }}
        showError={errorModalVisible}
        errorModalMessage={errorModalMessage}
      />

      <Modal visible={underAge} showClose={false} onClose={() => {}}>
        {!cartItems[0]?.isAdultsOnly && (
          <>
            <Modal.TextContent>
              Nepilnamečius registruoti turi tėvai ar globėjai ir vaiko
              informaciją pateikti lange pažymėjus “Registruoju vaiką iki 18
              metų. Pereiti į vaiko registraciją?
            </Modal.TextContent>
            <Modal.Actions>
              <Button
                size="large"
                variant="secondary"
                onClick={() => {
                  setUnderageModalVisibile(false);
                }}
              >
                Atšaukti
              </Button>
              <Button
                size="large"
                onClick={() => {
                  const patientName = formRef.current.values.patientName;
                  const patientSurname = formRef.current.values.patientSurname;
                  const patientIdNumber =
                    formRef.current.values.patientIdNumber;

                  formRef.current.values.otherPerson = true;
                  formRef.current.values.childName = patientName;
                  formRef.current.values.childSurname = patientSurname;
                  formRef.current.values.childIdNumber = patientIdNumber;
                  formRef.current.values.patientName = '';
                  formRef.current.values.patientSurname = '';
                  formRef.current.values.patientIdNumber = '';
                  formRef.current.errors.patientIdNumber = '';
                  setUnderageModalVisibile(false);
                }}
              >
                Tęsti
              </Button>
            </Modal.Actions>
          </>
        )}
        {cartItems[0]?.isAdultsOnly && (
          <>
            <Modal.TextContent>
              Nepilnamečius registruoti turi tėvai ar globėjai kategorijoje
              Vaikų gydytojų specialistų konsultacijos. Pereiti į Vaikų gydytojų
              specialistų konsultacijos kategoriją
            </Modal.TextContent>
            <Modal.Actions>
              <Button
                size="large"
                variant="secondary"
                onClick={() => {
                  setUnderageModalVisibile(false);
                }}
              >
                Atšaukti
              </Button>
              <Button
                size="large"
                onClick={async () => {
                  let response = await getServiceTypeId(
                    `?serviceTypeExternalId=${vgskServiceTypeId}`,
                  );
                  history.push(
                    `/services/${response.categoryId}/${response.id}`,
                  );
                  setUnderageModalVisibile(false);
                }}
              >
                Tęsti
              </Button>
            </Modal.Actions>{' '}
          </>
        )}
      </Modal>

      <Modal
        visible={showPhoneValidationModal}
        showClose={false}
        onClose={() => {
          setShowPhoneValidationModal(false);
        }}
      >
        <Modal.TextContent>
          Nurodytas telefono numeris {invalidPhoneNumber} yra klaidingo formato.
          Pakeisti numerį į {validPhoneNumber}?
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              setShowPhoneValidationModal(false);
            }}
          >
            Atsisakyti
          </Button>
          <Button
            size="large"
            onClick={() => {
              formRef.current.values.patientPhoneNumber = validPhoneNumber;
              formRef.current.validateField('patientPhoneNumber');
              saveValidationLog(
                `?changedFrom=${invalidPhoneNumber}&changedTo=${validPhoneNumber}`,
              );
              setShowPhoneValidationModal(false);
            }}
          >
            Pakeisti
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={showChildAgeRestrictionModal}
        showClose={false}
        onClose={() => {
          setShowChildAgeRestrictionModal(false);
        }}
      >
        {cartItems[0]?.isChildrenAgeRestrictionApplicable &&
        cartItems[0]?.childAgeFrom ? (
          <Modal.TextContent>
            Gydytojas konsultuoja tik vaikus nuo {cartItems[0]?.childAgeFrom}{' '}
            metų.
          </Modal.TextContent>
        ) : (
          <Modal.TextContent>
            Gydytojas konsultuoja tik vaikus iki 18 metų.
          </Modal.TextContent>
        )}
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              setShowChildAgeRestrictionModal(false);
            }}
          >
            Gerai
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};

export default BasketPage;
