import React, { useState, useEffect } from 'react';
import { formatCurrency, Money } from '../Money';
import Styled from './MultipleLaboratoryServicesSummary.styles';
import Tooltip from 'react-tooltip';
import { useTranslation, Trans } from 'react-i18next';
import { filter } from 'lodash';

const MultipleLaboratoryServicesSummary = ({
  serviceImage,
  allServices,
  isExtraVisit,
  isPrimaryVisit = true,
  removeFromLaboratoryMultipleServicesCallback,
  isPaid,
  correctChild = true,
  couponSum = 0,
  addMoreLaboratoryServicesButton,
  step,
  totalPrice = ()=>{},
  totalInitialPrice = ()=>{},
}) => {
  const { t } = useTranslation();
  const services = [
    { ...allServices[0], isPaidLaboratoryService: isPaid },
    ...allServices.slice(1),
  ];
  const isService = !services[0].specialistId;
  const [additionalServicesRulesApplied, setAdditionalServicesRulesApplied] =
    useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [totalSumWithDiscountCode, setTotalSumWithDiscountCode] = useState(0);

  useEffect(() => {
    const additionalServicesRulesApplied =
      selectAdditionalServicesRulesApplied();
    setAdditionalServicesRulesApplied(additionalServicesRulesApplied);
    setTotalSum(getTotalSum(additionalServicesRulesApplied));
    setTotalSumWithDiscountCode(
      getTotalSumWithDiscountCode(additionalServicesRulesApplied),
    );
  }, [allServices, isExtraVisit, isPrimaryVisit, correctChild, couponSum]);

  function selectAdditionalServicesRulesApplied() {
    var groupedAdditionalServices = services.reduce((result, service) => {
      service.additionalServices?.forEach((additionalService) => {
        const existingService = result.find(
          (group) => group[0].serviceId === additionalService?.serviceId,
        );
        additionalService = {
          ...additionalService,
          automationRuleCodes: service.automationRuleCodes,
          isPaidLaboratoryService: service.isPaidLaboratoryService,
        };
        if (existingService) {
          existingService.push(additionalService);
        } else {
          result.push([additionalService]);
        }
      });

      return result;
    }, []);
    let finalAdditionalServices = [];
    groupedAdditionalServices.forEach((group) => {
      if (!group.length) {
        return;
      }
      const isAnyDuplicationRules = group.some((item) =>
        item.automationRuleCodes.includes(3),
      );
      const isAnyNonPaidServices = group.some(
        (item) =>
          (item.isPaidLaboratoryService &&
            !item.automationRuleCodes.includes(2)) ||
          (!item.isPaidLaboratoryService &&
            !item.automationRuleCodes.includes(1)),
      );

      if (isAnyNonPaidServices && isAnyDuplicationRules) {
        return;
      }

      const filteredAdditionalServices = isAnyDuplicationRules
        ? [group[0]]
        : group;
      finalAdditionalServices = [
        ...finalAdditionalServices,
        ...filteredAdditionalServices,
      ];
    });

    return finalAdditionalServices;
  }

  const getPriceOfService = (service) => {
    return service.isPaidLaboratoryService && !isExtraVisit
      ? isPrimaryVisit
        ? !correctChild
          ? service.priceWithDiscountWithoutCard
          : service.priceWithDiscount
        : service.price
        ? !correctChild
          ? service.priceWithDiscountWithoutCardSecondary
          : service.secondaryDiscountEur
        : service.secondaryPrice
      : isExtraVisit
      ? !correctChild
        ? service.priceWithDiscountWithoutCardExtra
        : service.extraPrice
      : 0;
  };

  const getPriceOfServiceWithDiscountCode = (service) => {
    let regularPrice = getPriceOfService(service);
    return service.priceWithDiscountCode != null &&
      service.priceWithDiscountCode >= 0 &&
      service.priceWithDiscountCode < regularPrice
      ? service.priceWithDiscountCode
      : regularPrice;
  };

  const getTotalSumOfServicesPrices = () => {
    let sum = 0;

    services.forEach((service) => {
      sum += getPriceOfService(service);
    });

    return sum;
  };

  const getTotalSumOfServicesPricesWithDiscountCode = () => {
    let sum = 0;

    services.forEach((service) => {
      sum += getPriceOfServiceWithDiscountCode(service);
    });

    return sum;
  };

  const getTotalSum = (additionalServicesRulesApplied) => {
    const sumOfServices = getTotalSumOfServicesPrices();
    let sumOfAdditionalServices = 0;
    additionalServicesRulesApplied.forEach(
      (service) => (sumOfAdditionalServices += service.price),
    );
    return sumOfServices + sumOfAdditionalServices;
  };

  const getTotalSumWithDiscountCode = (additionalServicesRulesApplied) => {
    const sumOfServices = getTotalSumOfServicesPricesWithDiscountCode();
    let sumOfAdditionalServices = 0;
    additionalServicesRulesApplied.forEach((service) => {
      sumOfAdditionalServices +=
        service.priceWithDiscountCode != null &&
        service.priceWithDiscountCode >= 0 &&
        service.priceWithDiscountCode < service.price
          ? service.priceWithDiscountCode
          : service.price;
    });
    return sumOfServices + sumOfAdditionalServices;
  };

  const getFinalPrice = () => {
    const price = totalSumWithDiscountCode - couponSum >= 0
      ? totalSumWithDiscountCode - couponSum
      : 0;
    totalPrice(price);
    return price;
  };

  return (
    <>
      <Styled.Description>
        <Styled.Image style={{ backgroundImage: serviceImage }} />
        <Styled.Content>
          <Tooltip
            id="specialist"
            place="top"
            effect="solid"
            clickable={true}
            delayHide={1000}
            event="click"
            className="tooltip"
          >
            <Trans
              i18nKey={'registration.tlkTooltip'}
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href="https://storage.googleapis.com/afffideaglobal/SOP_LC_LT_064_Affidea_kliniku_ir_DC_Vidaus_tvarkos_taisykles_v_1_0_89bb60397d/SOP_LC_LT_064_Affidea_kliniku_ir_DC_Vidaus_tvarkos_taisykles_v_1_0_89bb60397d.pdf"
                    target="_parent"
                  />
                ),
              }}
            />
          </Tooltip>
          <Styled.MainTitle
            style={{
              borderRight: 0,
              fontWeight: '900',
            }}
          >
            {' '}
            {isService
              ? services[0].serviceTitle
              : services[0].specialistName + ' - ' + services[0].serviceTitle}
          </Styled.MainTitle>

          <Styled.DescriptionWrapper psdf={services[0].psdf}>
            <Styled.InfoBlock>
              <Styled.HeaderArea>
                {services[0].departmentTitle && t('common.workplace')}
                <span>
                  {services[0].departmentTitle && services[0].departmentTitle}
                </span>
              </Styled.HeaderArea>
              {services[0].cardTitle ? (
                <Styled.Badge style={{ width: 'fit-content' }} isService={true}>
                  {services[0].serviceTypeTitle}
                </Styled.Badge>
              ) : (
                <Styled.SpaceDiv></Styled.SpaceDiv>
              )}
            </Styled.InfoBlock>

            <Styled.InfoBlock>
              <Styled.MainPriceHeader>
                {(services[0].priceWithDiscount !== services[0].price &&
                  services[0].price !== 0 &&
                  services[0].discountType === 'NuolaiduKortele' &&
                  !isExtraVisit &&
                  'Kaina su Affidea nuolaidų kortele') ||
                  (isExtraVisit && 'Papildomo mokamo laiko kaina') ||
                  'Standartinė kaina'}
              </Styled.MainPriceHeader>
              <div>
                <Styled.Price>
                  <Money amount={getPriceOfService(services[0])} />
                </Styled.Price>
              </div>
            </Styled.InfoBlock>
          </Styled.DescriptionWrapper>
          <Tooltip
            id="tlkPrice"
            place="top"
            effect="solid"
            clickable={true}
            delayHide={1000}
            event="click"
            className="tooltip"
          >
            <Trans
              i18nKey={'registration.tlkTooltip'}
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href="https://storage.googleapis.com/afffideaglobal/SOP_LC_LT_064_Affidea_kliniku_ir_DC_Vidaus_tvarkos_taisykles_v_1_0_89bb60397d/SOP_LC_LT_064_Affidea_kliniku_ir_DC_Vidaus_tvarkos_taisykles_v_1_0_89bb60397d.pdf"
                    target="_parent"
                  />
                ),
              }}
            />
          </Tooltip>
          <Styled.InfoContainer>
            {services.slice(1)?.length > 0 && (
              <>
                <Styled.InfoRow>
                  <Styled.InfoMainName>
                    <strong> Papildomi laboratoriniai tyrimai:</strong>
                  </Styled.InfoMainName>
                  <Styled.InfoPrice></Styled.InfoPrice>
                </Styled.InfoRow>

                {services.slice(1).map((service, index) => (
                  <Styled.InfoRow>
                    <Styled.InfoName>{service.serviceTitle}</Styled.InfoName>
                    <Styled.InfoPrice>
                      <strong>
                        <Money amount={getPriceOfService(service)} />
                      </strong>

                      {!!removeFromLaboratoryMultipleServicesCallback && (
                        <span
                          style={{
                            color: 'red',
                            cursor: 'pointer',
                            marginLeft: '10px',
                          }}
                          onClick={() =>
                            removeFromLaboratoryMultipleServicesCallback(index)
                          }
                        >
                          X
                        </span>
                      )}
                    </Styled.InfoPrice>
                  </Styled.InfoRow>
                ))}
              </>
            )}
            {additionalServicesRulesApplied.length > 0 && (
              <>
                <Styled.InfoRow>
                  <Styled.InfoMainName>
                    <strong> Privalomos paslaugos:</strong>
                  </Styled.InfoMainName>
                  <Styled.InfoPrice></Styled.InfoPrice>
                </Styled.InfoRow>
                {additionalServicesRulesApplied?.map((service, index) => (
                  <Styled.InfoRow>
                    <Styled.InfoName>{service.name}</Styled.InfoName>
                    <Styled.InfoPrice>
                      {' '}
                      <strong>
                        <Money amount={service.price} />
                      </strong>
                    </Styled.InfoPrice>
                  </Styled.InfoRow>
                ))}
              </>
            )}
          </Styled.InfoContainer>
          <Styled.AdditionalInformation>
            <span
              dangerouslySetInnerHTML={{
                __html: t('common.priceDetails'),
              }}
            ></span>
          </Styled.AdditionalInformation>
          {step > 0 && addMoreLaboratoryServicesButton}
        </Styled.Content>
      </Styled.Description>
      {totalSumWithDiscountCode < totalSum && couponSum > 0 && (
        <Styled.TotalPrice>
          {`Iš viso su PVM (pritaikius nuolaidą): ${formatCurrency(
            totalSumWithDiscountCode,
          )}`}
        </Styled.TotalPrice>
      )}
      {step === undefined && (
        <Styled.Notification>
          {t('landing.hero.notification')}
        </Styled.Notification>
      )}
      <Styled.TotalPrice>
        {couponSum > 0
          ? `Iš viso su PVM (pritaikius dovanų kuponus): ${formatCurrency(getFinalPrice())}`
          : `Iš viso su PVM1 : ${formatCurrency(getFinalPrice())}`}
      </Styled.TotalPrice>
    </>
  );
};
export default MultipleLaboratoryServicesSummary;
