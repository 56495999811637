import React, { useState } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import useFetch from 'use-http';
import { Trans, useTranslation } from 'react-i18next';
import Container from '../../../shared/Container';
import Page from '../../../shared/Page';
import Button from '../../../shared/Button';
import Styled from './FinishRegistrationPage.styles';
import Form from '../../../shared/Form';
import TextField from '../../../shared/TextField';
import ErrorModal from '../../../shared/ErrorModal';
import Modal from '../../../shared/Modal';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Danger } from '../../../../assets/icons/danger.svg';
import { useUserState, useUserDispatch } from '../../../context/UserContext';
import useRedirection from '../../../hooks/useRedirection';
import { useEffectOnce } from 'react-use';
import personalCodeValidation from '../../../hooks/personalCodeValidation';
import validateEmail from '../../../hooks/validateEmail';
import { PhoneNumberType } from 'google-libphonenumber';
import { countBy } from 'lodash';

const UpdatePhone = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const sessionDispatch = useUserDispatch();
  const userState = useUserState();
  const [clearCartState] = useRedirection();
  const phoneUtil =
    require('google-libphonenumber').PhoneNumberUtil.getInstance();
  useEffectOnce(async () => {
    return () => {
      clearCartState();
    };
  }, [clearCartState]);
  const phoneNumberCountryCode =
    userState.countryCode === 'LV'
      ? '+3712'
      : userState.countryCode === 'ES'
      ? '+372'
      : '+3706';
  const initialValues = {
    phoneNumber: userState.phoneNumber
      ? userState.phoneNumber
      : phoneNumberCountryCode,
  };

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(t('validation.requiredField'))
      .test('checkFormat', function (val) {
        if (!val) {
          throw new Yup.ValidationError(
            t('validation.requiredField'),
            val,
            'phoneNumber',
          );
        }
        const standardPhoneRegex = /^\+(?:\d+)?$/;
        if (!standardPhoneRegex.test(val)) {
          throw new Yup.ValidationError(
            `Netinkami simboliai. Naudokite tik '+' ženklą ir skaičius po jo.`,
            val,
            'phoneNumber',
          );
        }
        if (!val.startsWith(phoneNumberCountryCode)) {
          throw new Yup.ValidationError(
            `Netinkamas valstybės kodas, turi prasidėti ${phoneNumberCountryCode}`,
            val,
            'phoneNumber',
          );
        }
        try {
          const number = phoneUtil.parseAndKeepRawInput(
            val,
            userState.countryCode,
          );
          const isNumberValid = phoneUtil.isValidNumber(number);
          if (!isNumberValid) {
            throw new Yup.ValidationError(
              `Netinkamas numerio formatas arba ilgis`,
              val,
              'phoneNumber',
            );
          }
          const isNumberMobile =
            phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE ||
            phoneUtil.getNumberType(number) ===
              PhoneNumberType.FIXED_LINE_OR_MOBILE;

          if (!isNumberMobile) {
            throw new Yup.ValidationError(
              `Turi būti mobilaus telefono numeris`,
              val,
              'phoneNumber',
            );
          }
        } catch (error) {
          throw new Yup.ValidationError(
            `Netinkamas numerio formatas arba ilgis`,
            val,
            'phoneNumber',
          );
        }
        return true;
      }),
  });

  const { post: updatePhonePost, response: updatePhoneResponse } = useFetch(
    `/UserAccount/UpdatePhone`,
  );

  const { post: sendSMSConfirmation, response: sendSMSConfirmationResponse } =
    useFetch(`/UserAccount/SendSMSConfirmation`);

  const handleUpdate = async (data, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const resp = await updatePhonePost('', {
      id: userState.id,
      phoneNumber: data.phoneNumber,
    });

    if (resp.isSuccess) {
      sessionDispatch({
        type: 'UPDATE',
        data: {
          phoneNumber: data.phoneNumber,
        },
      });

      await sendSMSConfirmation('', {
        phoneNumber: data.phoneNumber,
      });

      history.push('/change-password');
    } else {
      setErrorModalVisible(true);
    }

    setSubmitting(false);
  };
  return (
    <Page loading={false}>
      <Container>
        <Styled.Title>{t('userRegistration.title')}</Styled.Title>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
        >
          {({ setFieldValue, errors, isSubmitting, values }) => (
            <FormikForm>
              <Form>
                <Form.Group>
                  <Styled.SubTitle>
                    {t('userRegistration.personalData.title')}
                  </Styled.SubTitle>
                  <Form.Row>
                    <Field
                      labelStyle={{ fontSize: 20 }}
                      errorStyle={{ fontSize: 18 }}
                      disabled={
                        userState.initializedFromLogin &&
                        userState.authenticationType === 'mobileid'
                          ? true
                          : false
                      }
                      name="phoneNumber"
                      label={t('userRegistration.contactData.phone')}
                      component={TextField}
                    />
                  </Form.Row>
                </Form.Group>
              </Form>
              <Styled.Actions>
                <Form.Actions>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    size="huge"
                    type="submit"
                    onClick={() => handleUpdate}
                  >
                    {t('userRegistration.confirmData')}
                  </Button>
                </Form.Actions>
              </Styled.Actions>
            </FormikForm>
          )}
        </Formik>
      </Container>
      <ErrorModal
        onClose={() => {
          setErrorModalVisible(false);
        }}
        showError={errorModalVisible}
      />
      <Modal
        visible={updatePhonePost.error}
        onClose={() => window.location.reload()}
      >
        <Modal.Alert type="error">Atsiprašome, įvyko klaida</Modal.Alert>
        <Modal.TextContent>
          Atsiprašome įvyko nenumatyta klaida, bandykite dar kartą.
        </Modal.TextContent>
        <Modal.Actions>
          <Button size="large" onClick={() => window.location.reload()}>
            Uždaryti
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};
export default UpdatePhone;
