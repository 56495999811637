import { Form as FormikForm, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useSettingsState } from '../../../context/SettingsContext';
import { useUserState } from '../../../context/UserContext';
import Button from '../../../shared/Button';
import Checkbox from '../../../shared/Checkbox';
import Container from '../../../shared/Container';
import Form from '../../../shared/Form';
import Page from '../../../shared/Page';
import Styled from './RegisterPage.styles';

const RegisterTermsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { agreements } = useSettingsState();
  const userState = useUserState();

  const initialValues = {
    agreedWithDataProcessing: false,
    agreedWithTos: false,
    type: undefined,
    countryCode: 'LT',
  };

  const validationSchema = Yup.object().shape({
    agreedWithDataProcessing: Yup.boolean().isTrue(
      t('validation.dataAgreement'),
    ),
    agreedWithTos: Yup.boolean().isTrue(t('validation.patientTosAgreement')),
  });

  const handleVerification = async () => {
    if (userState?.authenticationType === 'amis') {
      history.push('/change-phone');
    } else {
      history.push('/register/info');
    }
  };

  return (
    <Page loading={false}>
      <Container>
        <Styled.Title>{t('userRegistration.initialPage.title')}</Styled.Title>
        <Styled.SubTitle>
          <span>{t('userRegistration.initialPage.rulesFromLogin')}</span>
        </Styled.SubTitle>
        <Styled.Form>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleVerification}
          >
            {({ setFieldValue, errors, isSubmitting, values }) => (
              <FormikForm>
                <Form>
                  <Form.Group>
                    <Form.Row>
                      <Checkbox
                        name="agreedWithDataProcessing"
                        error={errors.agreedWithDataProcessing}
                        onChange={(event) => {
                          setFieldValue(
                            'agreedWithDataProcessing',
                            event.target.checked,
                          );
                        }}
                      >
                        {t('userRegistration.agreements.registerAgeerements')}
                        <a
                          href={agreements.personalData.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {t('userRegistration.agreements.data')}
                        </a>
                      </Checkbox>
                    </Form.Row>
                    <Form.Row>
                      <Checkbox
                        name="agreedWithTos"
                        error={errors.agreedWithTos}
                        onChange={(event) => {
                          setFieldValue('agreedWithTos', event.target.checked);
                        }}
                      >
                        {t('userRegistration.agreements.registerAgeerements')}
                        <a
                          href={agreements.termsOfUse.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {t('userRegistration.agreements.tos')}
                        </a>
                      </Checkbox>
                    </Form.Row>
                  </Form.Group>

                  <Styled.Buttons>
                    <Button
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      size="mega"
                      type="submit"
                      style={{ width: 'inherit' }}
                      onClick={() => handleVerification}
                    >
                      {t('userRegistration.continue')}
                    </Button>
                  </Styled.Buttons>
                </Form>
              </FormikForm>
            )}
          </Formik>

          <Styled.Buttons>
            <Button
              size="huge"
              variant="secondary"
              onClick={async () => {
                history.push('/selfservice');
              }}
            >
              {t('userRegistration.cancel')}
            </Button>
          </Styled.Buttons>
        </Styled.Form>
      </Container>
    </Page>
  );
};
export default RegisterTermsPage;
