import React from 'react';
import Styled from '../../UserAccountPage.styles';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import { useHistory } from 'react-router-dom';
const LogDetails = () => {
  const history = useHistory();
  const log = history.location.state?.data;

  const request = log.request;
  const response = log.response;
  return (
    <Styled.DataContainer>
      <Styled.BackArrow>
        <RightArrow onClick={() => history.goBack()}></RightArrow>
        <Styled.VisitTitle>
          {log.calledMethod} {log.path}
        </Styled.VisitTitle>
      </Styled.BackArrow>
      <pre style={{ whiteSpace: 'pre-wrap' }}>
        {JSON.stringify(log, null, 2)}
      </pre>
      {request && (
        <div>
          Request:
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(JSON.parse(request), null, 2)}
          </pre>
        </div>
      )}
      {response && !log.path.includes('/api/Integrations/File/') && (
        <div>
          Response:
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {(() => {
              try {
                return JSON.stringify(JSON.parse(response), null, 2);
              } catch {
                return response; // Return the raw string if it's not valid JSON
              }
            })()}
          </pre>
        </div>
      )}
    </Styled.DataContainer>
  );
};
export default LogDetails;
